import React,{useState,useEffect} from "react";
import axios from 'axios';
import "./Footer.css";
import footer_logo  from "../../assets/CodePRo.jpg";
import Logo_dark from "../../assets/logwhitel.png";
import location  from "../../assets/contact/location.png";
import phone  from "../../assets/contact/phone.png";
import fax  from "../../assets/round-local-printshop-24px.png";
import fb  from "../../assets/contact/facebook.png";
import LI  from "../../assets/contact/linkden.png";

function Footer() {
  

  return (
      <footer id="footer" class="footer-container">
        
        <div className="sidebside">
            <div className="logimg">
              <img loading="lazy"
                    src={Logo_dark}
                    class="logo-image"
                    alt="CodePro logo"/>
              <h2>CodePro</h2>      
            </div>      
            <div className="Insa">
              <h2>INSA</h2>
              <h6>INSA has amended Proclamation No. 130/1999, the National Intelligence and Security Service Establishment Proclamation, to timely support Protect services of our country by facilitating bureaucratic processes.</h6>
            </div>  
            
            <div className="contactinfo">    
                <div className="contactinfottl">
                  <h2>Contact info</h2>
                </div> 

                <div className="grpiname">
                      <img
                        loading="lazy"
                        src={location}
                        className="icon"
                        alt="Location icon"
                      />አዲስ አበባ ,ኢትዮጵያ
                </div>
                <div className="grpiname">
                   <img
                      loading="lazy"
                      src={phone}
                      className="phone-icon"
                      alt="Phone icon"
                   />+251-113-71-71-14
                </div>      
                <div className="grpiname">
                  <a href='https://web.facebook.com/INSA.ETHIOPIA?_rdc=1&_rdr'><img
                        loading="lazy"
                        src={fb}
                        className="social-icon"
                        alt="Facebook icon"/>
                       Facebook
                  </a>
                </div>      
                        
                <div className="grpiname">
                   <a href='https://www.linkedin.com/company/information-network-security-agency/'> <img
                        loading="lazy"
                        src={LI}
                        className="social-icon"
                        alt="LinkedIn icon"/>
                       LinkedIn
                   </a>
                </div>        
            </div>  
        </div>
        <div className="footer-links">
          
          <div className="copyright">Copyright © 2024 • INSA.</div>
        </div>
      </footer>
  );
}

export default Footer;
