import React, { useEffect, useState } from "react";
import "./paidusr.css";
import axios from "axios";
import jQuery from 'jquery';

axios.defaults.withCredentials =true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'
const Paidusr = () => {
  
  const [data,setData] = useState([])
  
  useEffect(()=>{
    axios.get('https://codepro.insa.gov.et/paidcustomer/',{'withCredentials':true}).
    then((res)=>{
        console.log("from paid customer")
        console.log(res.data)
        setData(res.data)
    })
  },[]);

  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };


  return (
    <div className="admin_main-content">
      
      <div className="users-list">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Subscribe Length</th>
              <th>Subscribe Type</th>
              <th>Paid Amount</th>
              <th>Payment Date</th>
              <th>Payment method</th>
            </tr>
          </thead>
          <tbody>
           {data.map((datas)=>{ 
            return(
              <>
              <tr>
                <td>{datas['fname']}</td>
                <td>{datas['email']}</td>
                <td>{datas['phone']}</td>
                <td>{datas['pay']['subscriptionlen']}</td>
                <td>{datas['pay']['subscriptiontype']}</td>
                <td>{datas['pay']['amount']}</td>
                <td>{datas['pay']['paymentdate']}</td>
                <td>{datas['pay']['paymentmethod']}</td>                
              </tr>
              </>
            )
           })} 
          </tbody>
        </table>

        <div className="pagination">
          <span>&laquo;</span>
          <span className="active">1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>&raquo;</span>
        </div>
      </div>

     
    </div>
  );
};

export default Paidusr;
