import React, { useState } from 'react';
import jQuery from 'jquery';
import './signup.css';   
import logo from "../../assets/CodePRo.png";  
import axios from 'axios'; 
import { Link,useNavigate } from "react-router-dom";
import { validEmail, validPassword } from '../regex/regex';
import { Box } from '@mui/material';

axios.defaults.withCredentials = true;
const Signup =()=> {
    const navigate = useNavigate()
    const[emailErr,setemailErr]=useState(false)
    const[passErr,setpassErr]=useState(false)
    const[companyName,setcompanyName]=useState("")
    const[contactperson,setcontactperson]=useState("")
    const[firstname,setfirstname]=useState("")
    const[lastname,setlastname]=useState("")
    const[email,setemail]=useState("")
    const[phone,setphone]=useState("")
    const[password,setpassword]=useState("")
    const[password2,setpassword2]=useState("")
    const[paymentmethod,setpaymentmethod]=useState("")
    const[subscriptionlen,setsubscriptionlen]=useState("")
    const[subscriptiontype,setsubscriptiontype]=useState("")
    const[amount,setamount]=useState("")
    
    
    const get_cookie = (name) => {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
      };
      
     /*const validate=(e)=>{
        console.log('reach here')
        e.preventDefault();
        //if(from=='email'){
            if(validEmail.test(email)){
                console.log('invalid email')
                setemailErr(true)
              }
        //}
        //else{
            if(validPassword.test(password)){
                console.log('invalid pass') 
                if (password.length>=8){
                    console.log('pass length')
                  setpassErr(true)   
                }  
                
              }
        //}
        handleSubmit(e)
        
      };*/
    const validateEma=(emal)=>{
       if(validEmail.test(emal)){
           console.log('invalid email')
           setemailErr(true)
           setemail(emal)
         } 
    }
    const validatePass=(pas)=>{
        if (pas.length>=8){
            if(validPassword.test(pas)){
            console.log('pass length')
            setpassErr(true)   
            setpassword(pas)
            }
        }   
     }

    const handleSubmit = (e) => {
        //setCSRF()
        let axioConfig={
                    
            withCredentials:true,
          }
        if(paymentmethod===''){
            alert('Select payment method')
            return
        }
    
        e.preventDefault();
        console.log("in register")
        console.log(phone.startsWith("07")||phone.startsWith("09"))
        console.log(phone.length)
        console.log(document.querySelector('#subscription-length').value+ ' '+document.querySelector('#subscription-type').value+' '+document.querySelector('#payment-method').value)
        console.log(emailErr+' '+passErr)
        if(emailErr && passErr){
            if(phone.startsWith('09')||phone.startsWith('07')&&phone.length==10){
                if(password===password2){
                        axios.post("https://codepro.insa.gov.et/register/",{
                            first_name:firstname,
                            last_name:firstname,
                            email:email,
                            phone:phone,
                            password1:password,
                            password2:password2,
                            subscriptionlen:subscriptionlen,
                            subscriptiontype:subscriptiontype,
                            paymentmethod:paymentmethod,
                            amount:amount,
                            
                        },axioConfig)
                        .then((res) => {
                            console.log(res.data)
                            console.log(res.status)
                            setemailErr(false)
                            setpassErr(false)
                            /*const link = document.createElement('a')
                            link.href = "/Settings"
                            document.body.appendChild(link)
                            link.click()*/
                            sessionStorage.setItem('loged','Customer')
                            navigate("/Settings",{state:{id:"fromsgnup"}})
                        })
                        .catch((err)=>{
                            console.log(err.status)
                            
                        });
                        let fullname=firstname+" "+lastname
                        console.log(fullname)
                        
                        /*axios.post("https://gashasystem.insa.gov.et:4000/api/products/code-protection",{    
                            companyName:companyName,
                            contactPersonFullName:firstname,
                            contactPerson:firstname,
                            contactEmail:email,
                            contactPhone:phone,
                            
                        },axioConfig)
                        .then((res) => {
                            console.log(res.data)
                            console.log(res.status)
                            navigate("/Settings")
                        })
                        .catch((err)=>{
                            console.log(err.status)
                            
                        });*/
                    }
            
                else{
                    alert("there is password mismatch")
                }
            }
            else{
                alert("enter correct phone number start with 09 or 07 with 10 digit")
            }
        }
        else{
              if(!emailErr && !passErr){
                document.getElementById('errorsta').innerText="Correct your email format and combination of password character"
              }
              else if(!emailErr){
                document.getElementById('errorsta').innerText="Correct your email format"
              } 
              else if(!passErr){
                document.getElementById('errorsta').innerText="use above eight length character, symbol and number combination at once"
              } 
        }
    };
    

    const calculatePrice=()=>{
        setsubscriptionlen(document.querySelector('#subscription-length').value)
        setsubscriptiontype(document.querySelector('#subscription-type').value)
        setpaymentmethod(document.querySelector('#payment-method').value)
        let amounts
        console.log("reach here in calculate price")
        if (document.querySelector('#subscription-type').value=="Standard"){
            switch (document.querySelector('#subscription-length').value){
                case "Daily":
                    setamount(1000*1);
                    amounts=document.querySelector('#amount');
                    amounts.value=amount.toString()
                    break
                case "Monthly":
                    setamount(1000*2);
                    amounts=document.querySelector('#amount')
                    amounts.value=amount.toString()
                    break
                case "Annual":
                    setamount(1000*3);
                    amounts=document.querySelector('#amount')
                    amounts.value=amount.toString()
                    break
                default:
                    setamount(0);
                    amounts=document.querySelector('#amount')
                    amounts.value=amount.toString() 
                    break           
            }
        }
        else if (document.querySelector('#subscription-type').value=="Premium"){
            switch (document.querySelector('#subscription-length').value){
                case "Daily":
                    setamount(2000*1);
                    amounts=document.querySelector('#amount');
                    amounts.value=amount.toString()
                    break
                case "Monthly":
                    setamount(2000*2);
                    amounts=document.querySelector('#amount');
                    amounts.value=amount.toString()
                    break
                case "Annual":
                    setamount(2000*3);
                    amounts=document.querySelector('#amount');
                    amounts.value=amount.toString()
                    break
                default:
                    setamount(0);    
                    amounts=document.querySelector('#amount');
                    amounts.value=amount.toString()
                    break        
            }
        }
        else{
            setamount(0)
        }
        console.log(amount)
    }

   
    return (
        <>
        <Box sx={{bgcolor:'#e0e4deef'}}>
        <div className="container-signup">
            <div className="header-signup">
                <a href="/">
                    <img id='logimg' src={logo} alt="Logo" class="logo"/>
                </a>
                <div className="top-right">
                    <Link to="/signin" className="login-link">Already have an account? Log in</Link>
                    <Link to="/forgotPassword">Forget your user ID or password?</Link>
                </div>
            </div>
            <h1>Create an account</h1>
            <p id='errorsta'></p>
            <div className="form-container">
                <form onSubmit={handleSubmit} >
                    
                    <div className="form-group">
                        <div className="left">
                            <label htmlFor="firstname">Company Name</label>
                            <input type="text" id="firstnamez" name="firstname" required value={companyName} onChange={e=>setcompanyName(e.target.value)}/>
                        </div>
                        <div className="right">
                            <label htmlFor="lastname">Contact Person Full Name</label>
                            <input type="text" id="lastnamez" name="lastname"  value={firstname} onChange={e=>setfirstname(e.target.value)}/>
                        </div>
                        
                    </div>
                    
                    <div className="form-group">
                        <div className="left">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="emaill" name="email" required  onChange={e=>validateEma(e.target.value) }/>
                        </div>
                        <div className="right">
                            <label htmlFor="phone">Phone</label>
                            <input type="text" id="phone2" name="phone" required value={phone} onChange={e=>setphone(e.target.value)}/>
                          </div>                
                    </div>

                    <div className="form-group">
                        <div className="left">
                                <label htmlFor="password">Password</label>
                                <input type="password" id='password3' name="password" required  onChange={e=>validatePass(e.target.value)}/>
                         </div>
                        <div className="right">
                
                                <label htmlFor="password">Confirm Password</label>
                                <input type="password" id="password2" name="password2" required value={password2} onChange={e=>setpassword2(e.target.value)}/> 
                  
                        </div>
                        
                    </div>

                    <div className="form-group">
                        <div className="left">
                            <label htmlFor="subscription-type">Subscription Type</label>
                            <select id="subscription-type" name="subscription-type" onChange={calculatePrice}>
                                <option value="free">Free</option>
                                <option value="Standard">Standard</option>
                                <option value="Premium">Premium</option>
                            </select>
                        </div>
                        <div className="right">
                            <label htmlFor="subscription-length">Subscription Length</label>
                            <select id="subscription-length" name="subscription-length" onChange={calculatePrice}>
                                <option value="Once">Once</option>
                                <option value="Daily">Daily</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annual">Annual</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">                
                        <div className="left">
                            <label htmlFor="payment-method">Payment Method</label>
                            <select id="payment-method" name="payment-method" onChange={calculatePrice}>
                                <option value="">Select</option>
                                <option value="chapa">Chapa</option>
                                <option value="manual">Manual</option>
                            </select>
                        </div>
                        <div className="right">
                            <label htmlFor="amount">Amount</label>
                            <input type="text" id="amount" name="amount" readOnly />
                        </div>
                    </div>
                    
                    <button className="signupbtn" type="submit" >Register</button>
                </form>
                
            </div>
            <div className="terms-container">
                <p className="termssign">By creating an account, you agree to the <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a>.</p>
            </div>
        </div>
        </Box>
        </>
    );
}


export default Signup;
