import React, { useEffect, useState } from "react";
import "./archive.css";
import axios from "axios";
import jQuery from 'jquery';
//import { forEach } from "jszip";

axios.defaults.withCredentials=true;
const Archive = () => {
    const [data,setdata] =useState([])

  
  const get_pro=()=>{
    axios.get('https://codepro.insa.gov.et/upload/',{'withCredentials':true}).
    then((res)=>{
        //console.log(res.data)
        setdata(res.data)
    }).catch(()=>{
      alert("you have no project obfuscated previous")
    })
  }  

  useEffect(()=>{
    get_pro()
  },[]);

  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };
  
  let axioConfig={
    headers:{
      'X-CSRFToken':get_cookie('csrftoken')
    },
    withCredentials:true,
  }

  const removepro=(id)=>{
    const cofrm=window.confirm("are you sure to delete this project?")
    if(cofrm){
      console.log("remove project")
      axios.get("https://codepro.insa.gov.et/removepro/"+id,axioConfig)
        .then((res) => {
            //console.log(res.data)
            alert(res.data)
            get_pro()
        })
        .catch((err)=>{console.log(err.data)});
    }
  }

  const forcedownload=(res,projectname)=>{
    console.log(res)
    const file = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = file
    link.setAttribute('download',projectname+'.txt')
    document.body.appendChild(link)
    link.click()
  }

  const download =(id)=>{
    console.log(id)
   // console.log(projectname)
    axios.get('https://codepro.insa.gov.et/downloadfile/'+id).then((res)=>{
        //console.log(res)
        const link = document.createElement('a')
        link.href = 'https://codepro.insa.gov.et/downloadfile/'+id
        link.setAttribute('download',"projectname.zip")
        document.body.appendChild(link)
        link.click()//forcedownload(res,projectname)
    }).catch((err)=>console.log(err))

  }
  
  return (
    <>
    <h2>Obfuscated Project</h2>
    <table class="file-table">
    <thead>
        <tr>
            <th>Project Name</th>
            <th>Date</th>
            <th>Obfuscated File Size</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        
            {data.map((datas)=>{
                //console.log(data.length)
                //console.log(data.sort())
                return(
                    <>
                 <tr>   
                  <td>{datas['projectname']}</td>
                  <td>{datas['obfuscatedate']}</td>
                  <td>{datas['filesizeobf']}</td>
                  <td class="actions">
                        <span class="delete-icon-archive"><button onClick={()=>removepro(datas['id'])}>🗑️</button></span>
                        <span class="download-icon-archive"><button onClick={()=>download(datas['id'])}>⬇️</button></span>
                    </td>
                  </tr>
                    </>
                )
            })}
            
       
        
    </tbody>
</table>

    </>
  );
};

export default Archive;