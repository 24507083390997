import React, { useState } from 'react';
import Obfuscate from '../uploadSection/obfuscate';
import './obfuscatePage.css';  
import Payment from '../uploadSection/payment';
import Archive from '../uploadSection/archive';

const UploadPage = () => {
  const [activeTab, setActiveTab] = useState('obfuscate'); // Default active tab

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'obfuscate':
        return <Obfuscate />;
      case 'payment':
        return <Payment />;
      case 'archived':
        return <Archive />;
      default:
        return <Obfuscate />;
    }
  };
  

  return (
    <div className="container_upload">
      <h1 className="main-title">Get your product obfuscated today</h1>
      <p className="sub-title">Please upload your files below</p>
      <div className="tab-wrapper">
        <div className="tabs">
        <h1>Obfuscate</h1>
        </div>
        <div className="tab-content">
          <Obfuscate/>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;
