import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './code.css';   
import logo from "../../assets/CodePRo.png";  
import axios from 'axios'; 
import { useNavigate,useLocation } from "react-router-dom";
import Setnewpas from './setpass.jsx';
import Header from '../Header/header';
import Footer from '../Footer/Footer';

const Code =(email)=> {

    const [codes,setCode]=useState("");
    const navigate= useNavigate();
    const locat =useLocation();

    const handleSubmit = (e) => {    
        console.log(email)
        console.log(locat.state)
        console.log(locat.state.email)
        console.log(codes)
        e.preventDefault();
        axios.post("https://codepro.insa.gov.et/codecheck/",{
            email:locat.state.email,
            code:codes
        })
        .then((res) => {
            console.log(res.data)
            console.log(res.status)
            if(res.data==="correct"){
                navigate('/pass',{state:{email:locat.state.email}})
                //const root = ReactDOM.createRoot(document.getElementById('root'));
               // root.render(<Setnewpas emal={email.emal}/>);
            }
            else{
                alert("you enter wrong code");
            }
        })
        .catch((err)=>{
            console.log(err.status)
            
        });
    };

   
    return (
      <div>
        <Header/>  
      <section className='heroc-section'>  
       <div className='bodyc-login'>
        <div className="containerc-login">
           <div className='leftc-section'> 
            <div className="logoc-login">
                <a href="/">
                    <img src={logo} alt="Logo" class="logo"/>
                </a>
            </div>
            <div className="formc-container">
                <form onSubmit={handleSubmit}>
                    
                    <div className="formc-group">
                        <div className="leftc">
                            <label htmlFor="email">Code</label>
                            <input type="text" id="email" name="email" value={codes} onChange={e=>setCode(e.target.value)}/>
                            <small>enter code sent to your email</small>
                        </div>
                                        
                    </div>

                     
                    <button className="submit-button" type="submit">Submit</button>
                </form>
                
            </div>
            </div> 
            
        </div>
        </div>
        </section> 
        <Footer/>
        </div>
    );
};


export default Code;
