import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jQuery from 'jquery';
import './Feedback.css';

const FeedbackForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); 
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [feedbackList, setFeedbackList] = useState([]);

   
    /*useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await axios.get('http://172.20.136.72:8000/feedback/');
                setFeedbackList(response.data);
            } catch (error) {
                console.error('Error fetching feedback:', error);
            }
        };
        fetchFeedback();
    }, []);*/
    const get_cookie = (name) => {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
      };
      
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        let axioConfig={    
            /*headers:{
                'X_CSRFToken':get_cookie('csrftoken'),
              },        
            Credential:'include',     */
            withCredentials:true,
        }
        console.log(name+' '+message)
        try {
            await axios.post('https://codepro.insa.gov.et/feedback/',{
                name, message
            },axioConfig);
            setSuccessMessage('Thank you for your feedback!');
            setError('');
            setName('');
            setEmail('');
            setMessage('');

            //const response = await axios.get('https://codepro.insa.gov.et/feedback/');
            //setFeedbackList(response.data);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('Failed to submit feedback. Please try again.');
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
    <div className="container">
        <div className="feedback-form">
            <h2 className="text-center mb-4">We Value Your Feedback!</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-groupfee">
                    <label htmlFor="name">Subject</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                
                <div className="form-groupfee">
                    <label htmlFor="message">Feedback</label>
                    <textarea
                        className="form-control"
                        id="message"
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)}
                        rows="4"
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit Feedback'}
                </button>
                {successMessage && <p style={{ color: 'green', marginTop: '10px', textAlign: 'center' }}>{successMessage}</p>}
                {error && <p className="mt-3 text-danger" aria-live="polite">{error}</p>}
            </form>
        </div>
    </div>

    );
};

export default FeedbackForm;
