import React from "react";
import "./HowItWorks.css";
import video1 from "../../assets/describe.mov";

function HowItWorks() {
  return (
    <section >
      <div className="howItWorks_container">
        <h1>How it works</h1>
        <div className="video-container">
        <h1>Coming Soon..</h1>
          
        </div>
      </div>
      </section>
  );
}

export default HowItWorks;
