import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import './Dashboard.css';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const Dashboard = () => {
  const [infochar,setinfochar] = useState([{}])
  // Data for the charts
 /* const userData = {
    labels: ['Admins', 'Super Admins', 'HR Admins', 'Employees'],
    datasets: [
      {
        label: 'Number of Users',
        data: [12, 5, 7, 20], // Example data
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };*/
  useEffect(()=>{
    axios.request('https://codepro.insa.gov.et/forchart/').
    then((res)=>{
      console.log(res.data)
      setinfochar(res.data)
      console.log(infochar)
    })
  },[]);

  const userData = {
    labels: ['Free User','Standard User', 'Premium User'],
    datasets: [
      {
        data: [infochar[0],infochar[1],infochar[2]], // Example data
        backgroundColor: ['#36A2EB', '#FF6384','#AA6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384','#AA6384'],
      },
    ],
  };

  const filesData = {
    labels: ['Uploaded Files', 'Obfuscated Files'],
    datasets: [
      {
        data: [infochar[8],infochar[9]], // Example data
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const pieChartData = {
    labels: ['Python', 'Java', 'C', 'Net','Others'],
    datasets: [
      {
        data: [infochar[3], infochar[4],infochar[5],infochar[6],infochar[7]], // Example data
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0','#76AEEB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0','#76AEEB'],
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <h2>Usage Statics</h2>

      <div className="charts-row">
        {/* Bar Chart for Users */}
        <div className="chart-card">
          <h3>User Distribution</h3>
          <Pie data={userData}  />
        </div>
      
        {/* Pie Chart for Uploaded Files */}
        <div className="chart-card">
          <h3>Files Obfuscated</h3>
          <Pie data={filesData} />
        </div>
      
        {/* Additional Pie Chart */}
        <div className="chart-card">
          <h3>File Types Uploaded</h3>
          <Pie data={pieChartData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
