import React from "react";
import left_arrow from "../../assets/arrow_left.png";
import right_arrow from "../../assets/arrow_right.png";
import Stars from "../../assets/stars.png";
import "./Stats.css";

function Stats() {
  return (
    <section className="stats-section">
      <div className="stats_container">
        <div className="stat">
          <h3>99%</h3>
          <p>Client Retention</p>
        </div>
        <div className="stat">
          <h3>7</h3>
          <p>Years of Service</p>
        </div>
        <div className="stat">
          <h3>30+</h3>
          <p>Team of Professionals</p>
        </div>
        <div className="stat">
          <h3>221+</h3>
          <p>Satisfied Clients</p>
        </div>
      </div>

      <div className="happy_cients">
        <h1>Happy Clients About Us</h1>
      </div>

      <div className="happy">
        <div className="stats_container2">
          <div className="inside_row">
            <img src={left_arrow} alt="arrow" className="left_arrow" />
            <div className="we_are_happy">
              <p>We are happy to hear from you</p>
            </div>

            <div className="inside_column">
              <img src={Stars} alt="stars" className="stars" />
              <h3>- Fashion Invogue</h3>
              <h4>Director</h4>
            </div>
            <img src={right_arrow} alt="arrow" className="right_arrow" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
