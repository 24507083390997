import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './forgotPass.css';   
import logo from "../../assets/CodePRo.png";  
import axios from 'axios'; 
import Code from '../../components/codecheck/code.jsx';

import { useNavigate } from "react-router-dom";
import Signup from '../signup/signup';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/header";
const ForgotPass =()=>{
    const[email,setEmail]=useState("");
    const navigte = useNavigate();
    const handleSubmit = (e) => {    
        e.preventDefault();
        console.log(email)
        axios.post("https://codepro.insa.gov.et/forgotpass/",{
            email:email,
        })
        .then((res) => {
            if (res.status===200){
                navigte('/code',{state:{email:email}})
                //const root = ReactDOM.createRoot(document.getElementById('root'));
                //root.render(<Code emal={this.state.email} />);
            }
            else{
                alert("your email is not registered")
                navigte('/signup')
                //const root = ReactDOM.createRoot(document.getElementById('root'));
                //root.render(<Signup />);
            }
            
        })
        .catch((err)=>{
            console.log(err.status)
            
        });
    };

  
    return (
        <>
        < Header/>
        <section className='forgot-section'>
        <div className='body-forgot'>
        <div className="container-forgot">
             <div className="logo-forgot">
                <a href="/">
                    <img src={logo} alt="Logo" class="logo"/>
                </a>
                
            </div>
             <div className="forgot-container"> 
                 <form onSubmit={handleSubmit}>
                    
                    <div className="forgot-group">
                        <div className="forgot-left">
                            <label>Enter Email You Registered </label>
                            <input type="email" id="emailforgot" name="email" value={email} onChange={e=>setEmail(e.target.value)}/>
                            <small>This will help verify your account and keep it safe.</small>
                            <button id="sendcode" type="submit">Send</button>
                        </div>
                                        
                    </div>

                </form> 
                
             </div> 
            
        </div>  
            
        </div>
        
        </section>
         <Footer/> 
        </>
    );

}

export default ForgotPass;
