import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from "./pages/homePage/homePage";
import ReactDOM from 'react-dom/client';
import UploadPage from "./pages/upload/upload";
import Pricing from "./pages/Pricing/pricingPage"
import LoginPage from "./pages/login/loginPage";
import Signup from "./pages/signup/signup";
import ForgotPass from "./pages/forgotPass/forgotPass";
import Services from "./pages/Services/Services";
import Hwitwork from "./components/HowItWorks/HowItWorks";
import Setting from "./pages/Settings/Settings";
import Admidash from "./pages/Admin/admin_home";
import Code from "./components/codecheck/code";
import Pass from "./components/codecheck/setpass";
import Approve from "./pages/Approver/Approver";
import Footer from "./components/Footer/Footer";
import axios from 'axios';

function App() {
  
  return (
    <div className="App_for_all">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/attach" element={<UploadPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/code" element={<Code />} />
          <Route path="/pass" element={<Pass />} />
          <Route path="/forgotPassword" element={<ForgotPass />} />
          <Route path="/service" element={<Services />} />
          <Route path="/Settings" element={<Setting />} />
          <Route path="/hitw" element={<Hwitwork />}/>
          <Route path="/Admin" element={<Admidash />} />
          <Route path="/Approver" element={<Approve />} />
          <Route path="/footer" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

