import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import "./Settings.css";
import EditProfile from "../../components/ProfileForm/ProfileForm";
import Feedback from "../../components/Feedback/Feedback";
import Payment from "../../components/uploadSection/payment";
import { useNavigate,useLocation} from "react-router-dom";
import Obfuscate from "../../components/obfuscatePage/obfuscatePage";
import Archieved from "../../components/uploadSection/archive";
import admin_logo from "../../assets/CodePRo.png";


import axios from "axios";

const Settings = (id) => {
  const [activeTab, setActiveTab] = useState(""); // Set 'edit-profile' as default
  const [ids,setId] = useState("")
  const navigate = useNavigate();
  const locate =useLocation();

  useEffect(()=>{
    console.log(locate.state)
    console.log("settings")
    console.log(sessionStorage.getItem('loged'))
    console.log(sessionStorage.length)
    
    if(sessionStorage.length===0){
      navigate("/signin")
      alert("first you should login")
    } 
    else{
      setActiveTab(locate.state.id)
    }
    //renderComponent()
  },[]);

  const renderComponent = () => {
    
    switch (activeTab) {
      case "edit-profile":
        return <EditProfile id={ids}/>;
      case "help":
        return <Feedback id={ids}/>;
      case "upload":
        return <Obfuscate id={ids}/>;
      case "archieved":
        console.log("reach here in archive");
        return <Archieved id={ids}/>;  
      default:
        return <Archieved id={ids}/>;
    }
  };

  const backHome=()=>{
    axios.request("https://codepro.insa.gov.et/logut/").
    then((res)=>{
      console.log(res.data)
      sessionStorage.removeItem('loged')
      navigate('/signin')
      /*const link = document.createElement('a')
      link.href = "/login"
      document.body.appendChild(link)
      link.click()*/
    })
  }

  return (
      
      <div className="sidebar_container">
        <div className="sidebar-menu">
        <img id='logimg' src={admin_logo} alt="Admin Logo" />

          <ul>
            <li
              className={`sidebar-item ${
                activeTab === "edit-profile" ? "active" : ""
              }`}
              onClick={() => setActiveTab("edit-profile")}
            >
              Edit profile
            </li>
            <li
              className={`sidebar-item ${
                activeTab === "upload" ? "active" : ""
              }`}
              onClick={() => setActiveTab("upload")}
            >
              Upload
            </li>
            <li
              className={`sidebar-item ${
                activeTab === "archieved" ? "active" : ""
              }`}
              onClick={() => setActiveTab("archieved")}
            >
              Archieved
            </li>
            <li
              className={`sidebar-item ${activeTab === "help" ? "active" : ""}`}
              onClick={() => setActiveTab("help")}
            >
              Feedback
            </li>
          </ul>
        </div>
        
        <div className="user_page">
          <div className="user_navbar">
            <div class="admin_welcome"></div>    
            <button onClick={backHome}>Logout</button>
          </div>
             
        <div >{renderComponent()}</div>
      </div>
      </div>
       
  );
};

export default Settings;
