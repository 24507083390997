import React, { useState,useEffect } from "react";
import ReactDOM from 'react-dom/client';
import profile_pic from "../../assets/profile-pic.png";
import "./Approver.css";
import axios from "axios";
import jQuery from 'jquery';
import Login from "../login/loginPage";
import admin_logo from "../../assets/CodePRo.png";
import { Link, useNavigate} from "react-router-dom";

axios.defaults.withCredentials =true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken' 
const Approver = () => {
  const[data,setdata]=useState([])
  const navigate = useNavigate()

  const get_usr=()=>{
    axios.get('https://codepro.insa.gov.et/approve/',{'withCredentials':true}).
    then((res)=>{
      console.log(res.data)  
      setdata(res.data) 
    }).catch((err)=>{console.log(err)});
  }

  useEffect(()=>{
    if(sessionStorage.length===0){
      navigate("/signin")
      alert("first you should login")
    } 
    else{
      get_usr()
    }
    
  },[]);
  const backHome=()=>{
    axios.request("https://codepro.insa.gov.et/logut/").
    then((res)=>{
      console.log(res.data)
      sessionStorage.removeItem('loged')
      navigate("/signin")
      /*const link = document.createElement('a')
      link.href = "/login"
      document.body.appendChild(link)
      link.click()
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(<Login/>);*/
    })
  }
  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };

  let axioConfig={
    headers:{
      'X-CSRFToken':get_cookie('csrftoken')
    },
    withCredentials:true,
  }

  const makepaid = (id)=>{
    console.log('id of '+id.id)

    axios.post("https://codepro.insa.gov.et/approve/",{
      id:id,
    },axioConfig).
    then((res)=>{
      console.log(res.data)
      get_usr()
    })
    .catch((err)=>{console.log(err.data)});
  }

  const checker=(item,index)=>{
    console.log(document.getElementById('searchtxt').value)
    let str=document.getElementById('searchtxt').value
    if(item['email']===str){
      //console.log(item)
      setdata([item])
      //console.log(data)
    }
    if(str===""){
      get_usr()
      alert("not found")
    }
  }

  const displaylisted=()=>{
    
    data.forEach(checker)

  }

  return (
    <>
      <div class="approver_content">
          
        
        <div class="approver_search-bar">
          <img src={admin_logo} alt="CodePro Logo" />
          <input type="text" id='searchtxt' placeholder="Search" onChange={displaylisted}/>
          <div class="sort-by">
            
            <button className="appbtn" onClick={backHome}> <i class="icon-check"> Logout</i></button>
          </div>
        </div>
        <div class="document-list">
          <h2>List of unpaid Customers</h2>
          <table>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Subscribe Length</th>
              <th>Subscribe Type</th>
              <th>Paid Amount</th>
              <th>Pay Date</th>
              <th>Actions</th>
            </tr>
           
              {data.map((datas)=>{
                return(<>
                 <tr>
                <td>{datas['fname']} {datas['lname']}</td>
                <td>{datas['email']}</td>
                <td>{datas['phone']}</td>
                <td>{datas['pay']['subscriptionlen']}</td>
                <td>{datas['pay']['subscriptiontype']}</td>
                <td>{datas['pay']['amount']}</td>
                <td>{datas['pay']['paymentdate']}</td>
                <td class="actions">
                <button>
                  <i class="icon-check" onClick={()=>makepaid(datas['pay']['id'])}> Approve</i>
                </button>
                
                </td>
                </tr>
                </>)
                
              })}
            
            
          </table>
        </div>
      </div>
    </>
  );
};

export default Approver;
