import React from "react";
import "./Describe.css";
import ReactDOM from 'react-dom/client';
import Signup from "../../pages/signup/signup";
import video from "../../assets/video.mp4";
import video1 from "../../assets/describe.mov";
import { Link } from "react-router-dom";

function HeroSection() {
  const onClicksu = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<Signup/>); 
  };

  const txt=""
  return (
    <section className="describe-section">
      <div className="describe_container">
        <div className="describe_moto">
          <h1>CodePro</h1>
          <div className="parg">
            <p>CodePro is an online anti-reverse engineering system to protect software developed by using various programming languages. It employs a variety of techniques and strategies to make the software hard for accessing its code and design principles with the help of reverse engineering mechanisms. Thus,it aims to preserve the intellectual property of the developer.</p>
          </div>   
             <Link to="/signup">
              <button className="btn-getStarted" >Get started</button>
             </Link>  
        </div>
        <div className="expvd">     
        <video className="video1" autoplay='true' loop='true' ><source src={video1} type=""/>Your browser does not support the video tag.</video>
        </div>
      </div>
       
     
    </section>
  );
}

export default HeroSection;
