import React from "react";
import Header from "../../components/Header/header";
import "./pricingPage.css";
import Footer from "../../components/Footer/Footer";

const Pricing = () => {
  
  return (
    <>
    <div id="pricing" className="pricng_app">
        <Header />
        <section class="pricing-section">
        <div class="pricing-container">
            <header class="pricing-header">
                <h2 class="pricing-title">Choose your plan</h2>
            </header>
        </div>
        <div class="pricing-plans">
            
            <article class="plan-card premium-plan">
                <div class="plan-content">
                    <header class="plan-header">
                        <h3 class="plan-title">Free</h3>
                    </header>
                    
                    <div class="plan-cta">
                        <a href="/signup" class="cta-button">Create account</a>
                    </div>
                    <ul class="plan-features">
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>List Obfuscation Technique</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>List file in project</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Obfuscate least size of file among project</span>
                        </li>
                    </ul>
                </div>
                
            </article>
            <article class="plan-card premium-plan">
                <div class="plan-content">
                    <header class="plan-header">
                        <h3 class="plan-title">Standard</h3>
                    </header>
                    
                    <div class="plan-cta">
                        <a href="/signup" class="cta-button">Create account</a>
                    </div>
                    <ul class="plan-features">
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Obfuscate using String</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Rename and add junk technique</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Obfuscate python projects</span>
                        </li>
                    </ul>
                </div>
            </article>
            <article class="plan-card premium-plan">
                <div class="plan-content">
                    <header class="plan-header">
                        <h3 class="plan-title">Premium</h3>
                    </header>
 
                    <div class="plan-cta">
                        <a href="/signup" class="cta-button">Create account</a>
                    </div>
                    <ul class="plan-features">
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Obfuscate using encryption</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Anti-debug</span>
                        </li>
                        <li class="feature-item">
                            <i class='feature-icon'>✔</i>
                            <span>Obfuscate c/c++,java and .Net projects</span>
                        </li>
                    </ul>
                </div>
            </article>
        </div>
    </section>
    </div>
    </>
  );
};

export default Pricing;