import React, { useEffect, useState } from "react";
import "./admin_user.css";
import { validEmail, validPassword } from '../regex/regex';
import axios from "axios";
import jQuery from 'jquery';


axios.defaults.withCredentials =true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'
const Admin_user = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data,setData] = useState([])
  const[emailErr,setemailErr]=useState(false)
  const[passErr,setpassErr]=useState(false)

  const [firstname,setfirstname] = useState("")
  const [lastname,setlastname] = useState("")
  const [email,setemail] = useState("")
  const [phone,setphone] = useState("")
  const [password,setpassword] = useState("")
  const [password1,setpassword1] = useState("")
  const [role,setrole] = useState()

  const get_usr=()=>{
    axios.get('https://codepro.insa.gov.et/adduser/',{withCredentials:true}).
    then((res)=>{
        //console.log(res.data)
        setData(res.data)
    })
  }
  useEffect(()=>{
    get_usr()
  },[]);

  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };

  const validate=(from)=>{
    console.log('reach here')
    
    if(from=='email'){
        if(validEmail.test(email)){
            console.log('invalid email')
            setemailErr(true)
          }
    }
    else{
        if(validPassword.test(password)){
            console.log('invalid pass') 
            if (password.length>=8){
                console.log('pass length')
              setpassErr(true)   
            }  
            
          }
    }
    
  };

  let axioConfig={
    headers:{
      'X-CSRFToken':get_cookie('csrftoken')
    },
    withCredentials:true,
  }

  const removeuser =(id,role)=>{
    console.log("id of user")
    console.log(id)
    const cofrm=window.confirm("are you sure to delete this user?")
    
    if(role!==1){
      if(cofrm){
        axios.get("https://codepro.insa.gov.et/removeuser/"+id,axioConfig)
        .then((res) => {
            console.log(res.data)
            alert(res.data)
            get_usr()
        })
        .catch((err)=>{console.log(err.data)});
      }
    }
    else{
      alert("You can not delete user ")
    }

    }
  
  
  const handleSubmit = function(e) {
    e.preventDefault();
    console.log(firstname)
    
    validate('email')
    validate('pass')
    console.log(emailErr+' '+passErr)
    
    if(document.querySelector('#role').value==="Admin"){
      setrole(1)
    }
    else if(document.querySelector('#role').value==="Approver"){
      setrole(2)
    }
    else{
      setrole(3)
    }
    console.log(phone.startsWith("07"||"09"))
    console.log(phone.length)
    
    if(emailErr && passErr){
    if(phone.startsWith('09')||phone.startsWith('07')&&phone.length==10){
          
      if(password===password1){
        axios.post("https://codepro.insa.gov.et/adduser/",{
            
            first_name:firstname,
            last_name:lastname,
            email:email,
            phone:phone,
            password1:password,
            password2:password1,
            role:role
        },axioConfig)
        .then((res) => {
          console.log("reach here")
            console.log(res.data)
            setIsModalOpen(false);
            get_usr()
        })
        .catch((err)=>{console.log(err.data)});
      }
      else{
        alert("enter same password")
      }
    }
    else{
      alert("enter correct phone number start with 09 or 07 with 10 digit")
    }
  }
  else{
    if(!emailErr && !passErr){
      document.getElementById('errorsta').innerText="Invalid email format and password"
    }
    else if(!emailErr){
      document.getElementById('errorsta').innerText="Invalid email format"
    } 
    else if(!passErr){
      document.getElementById('errorsta').innerText="Put eight length password "
    } 
  }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setfirstname("")
    setlastname("")
    setemail("")
    setpassword("")
    setpassword1("")
    setphone("")
    setIsModalOpen(false);
  };
  
  const checker=(item,index)=>{
    console.log(document.getElementById('searchtxt').value)
    let str=document.getElementById('searchtxt').value
    if(item['email']===str){
      //console.log(item)
      setData([item])
      //console.log(data)
    }
    if(str===""){
      get_usr()
      alert('not found')
    }
  }
  const displaylisted=()=>{
    
    data.forEach(checker)

  }

  return (
    <div className="admin_main-content">
      <div className="dashboard-header">
        <h4>Registered Users</h4>
        <div className="search-bar">
          <input type="text" id='searchtxt' placeholder="Search" onChange={displaylisted}/>
          <button className="add-user-btn1" onClick={openModal}>Add User +</button>
        </div>
      </div>

      <div className="users-list">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Phone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
           {data.map((datas)=>{ 
            return(
              <>
              <tr>
                <td>{datas['first_name']}<br /><small>{datas['email']}</small></td>
                <td>{datas['role']}</td>
                <td>{datas['phone']}</td>
                <td className="action-icons">
                  <i className="icon-delete"><button className='dltbtn' onClick={()=>removeuser(datas['email'],datas['role'])}>Delete</button></i>
                </td>
              </tr>
              </>
            )
           })} 
          </tbody>
        </table>

        <div className="pagination">
          <span>&laquo;</span>
          <span className="active">1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>&raquo;</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <h2>Add New User</h2>
            <p id='errorsta'></p>
            <form onSubmit={handleSubmit}>
              <div className="form-aduser">
                  <label>First Name</label>
                  <input type="text" id="firstnamea" name="firstname" value={firstname} onChange={e=>setfirstname(e.target.value)} />
              </div>
              <div className="form-aduser">  
                  <label>Last Name</label>
                  <input type="text" id="lastnamea" name="lastname" value={lastname} onChange={e=>setlastname(e.target.value)} />
                
              </div>
              <div className="form-aduser">
              <label>Phone</label>
              <input type="text" id="phone1" name="phone" value={phone} onChange={e=>setphone(e.target.value)}/>
              </div>
              <div className="form-aduser">
                <label>Email</label>
                <input type="email" id="email2a" name="email" value={email} onChange={e=>setemail(e.target.value)}/>
              </div>
              <div className="form-aduser">
              <label>Role</label>
              <select id="role" name="role">
                <option value="Admin">Admin</option>
                <option value="Approver">Approver</option>
              </select>
              </div>
             
              <div className="form-aduser">
              <label>Password</label>
              <input type="password" id="password" name="password" value={password} onChange={e=>setpassword(e.target.value)}/>
              </div>
              <div className="form-aduser">
              <label>Confirm Password</label>
              <input type="password" id="password1" name="password1" value={password1} onChange={e=>setpassword1(e.target.value)}/>
              </div>
              <div className="form-actions">
                <button type="button" onClick={closeModal}>Cancel</button>
                <button className='subbtn 'onClick={handleSubmit}>Register</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin_user;
