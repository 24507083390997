// Suggested code may be subject to a license. Learn more: ~LicenseLog:2740308433.
import React, {useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import "./header.css";
// import Logo_light from "../../assets/Logo_light.png";
import Logo_dark from "../../assets/CodePRo.png";
import ReactDOM from 'react-dom/client';
// import ObfuscatePage from "../../pages/obfuscatePage";
import Home from "../../pages/homePage/homePage"


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoginPage from "../../pages/login/loginPage";

function Header() {
 
  const [menuOpen, setMenuOpen] = useState(false);
  

  const toggleMenu = () => {
    console.log(!menuOpen)
    setMenuOpen(!menuOpen);
  };

  const scrollToFooter = (e) => {
    //e.preventDefault();
    console.log(e)
    const footerElement = document.getElementById(e);
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Box sx={{ display: 'flex'}}>
          <AppBar component="nav"  sx={{ bgcolor:"white"}}>
            <Toolbar>
              
              <Box
                component='img'
                sx={{
                  height:63,
                  width:90,
                }}
                alt='CodePro'
                src={Logo_dark}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' },color:"black" }}
              >
               
              </Typography>

              <Box
                className="hamburger"
                onClick={toggleMenu}
                sx={{
                  display: { xs: 'flex', sm: 'none' }, // Only show on small screens
                  cursor: 'pointer',
                  zIndex: 1000, // Ensure it's clickable
                  position: 'relative', // Avoid overlapping issues
                }}
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </Box>

              <Box >
                <nav className={`nav ${menuOpen ? "mobile" : ""}`}>
                  <Link  to="#" onClick={()=>scrollToFooter("describe")}>Home</Link>
                  <Link to="#" onClick={()=>scrollToFooter("service")}>Features</Link>
                  <Link to="#" onClick={()=>scrollToFooter("pricing")}>Packages</Link>
                  <Link to="#" onClick={()=>scrollToFooter("hitw")}>Help</Link>
                  <Link to="#" onClick={()=>scrollToFooter("footer")}>Contact Us</Link>
                 
                  
                  <Link to="/signin"><button className="btn-start" >Sign In</button></Link>
                  
                </nav>   
              </Box>
            </Toolbar>
          </AppBar>
          
    </Box>    
        
  )
}

export default Header;
