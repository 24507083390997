import React, { useState, useEffect } from "react";
import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import service3 from "../../assets/service3.png";
import service4 from "../../assets/folder.png";
import service31 from "../../assets/service31.jpg"
import service21 from "../../assets/service21.jpg"
import service11 from "../../assets/service11.jpg"
import service33 from "../../assets/service33.jpg"
import "./Services.css";


function ServiceDetails1() {
  return (
    <div className="service-details">
      <h3>Advanced Code Protection</h3>
      <p>Our cutting-edge obfuscation algorithms ensure your software remains protected from reverse engineering attempts.</p>
    </div>
  );
}

function ServiceDetails2() {
  return (
    <div className="service-details">
      <h3>Multiple File Support</h3>
      <p>Our platform supports a wide range of software types, easily handling .exe, .dll, .jar, and other formats.</p>
    </div>
  );
}

function ServiceDetails3() {
  return (
    <div className="service-details">
      <h3>Fast and Secure</h3>
      <p>We ensure rapid obfuscation without compromising the safety or integrity of your software.</p>
    </div>
  );
}

function ServiceDetails4() {
  return (
    <div className="service-details">
      <h3>Multiple Coding Support</h3>
      <p>Unlock the power of secure coding with C/C++, C#, Java, and Python! Our process ensures code obfuscation that protects your work without sacrificing functionality. Enjoy robust security across multiple languages, keeping your logic safe and fully operational. With seamless transformations, your code stays both efficient and secure.</p>
    </div>
  );
}

function Services() {
  const slides = [
    {
      img: service31,
      component: <ServiceDetails1 />,
    },
    {
      img: service21,
      component: <ServiceDetails2 />,
    },
    {
      img: service11,
      component: <ServiceDetails3 />,
    },
    {
      img: service33,
      component: <ServiceDetails4 />,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => clearInterval(timer); 
  }, []);

  return (
    <section className="services-section">
      <div className="services-container">
        <h1>Main Features</h1>

        
        <div className="services">
        
          <div className="slider">
            <div className="slider-slide">
              <div className="service-slide-content">
                <div className="service-img-container">
                  <img
                    src={slides[currentIndex].img}
                    alt={`Service ${currentIndex + 1}`}
                    className="service-img"
                  />
                </div>
                <div className="service-content-container">
                  {slides[currentIndex].component}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
  );
}

export default Services;
