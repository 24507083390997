import React from "react";
import Header from "../../components/Header/header";
import ObfuscatePage from "../../components/obfuscatePage/obfuscatePage";
import Footer from "../../components/Footer/Footer";
import "./upload.css";

function UploadPage() {
  return (
    <div id="upload" className="App">
      <Header />
      <ObfuscatePage />
      <Footer />
    </div>
  );
}

export default UploadPage;
