import React from "react";
import Header from "../../components/Header/header.jsx";
import Describe from "../../components/Description/Describe.jsx";
import Pricing from "../Pricing/pricingPage.jsx";
import Services from "../Services/Services.jsx";
import HowItWorks from "../../components/HowItWorks/HowItWorks.jsx";
import Stats from "../../components/Stats/Stats.jsx";
//import Clients from "../../components/Clients/Client.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Fanchr from "../../components/FancyHr/FancyHr.js";
import "./homePage.css";

const HomePage=()=> {
  return (
    <div className="home">
      <div id='describe' className="describe"><Describe /></div>
      <div className="header"><Header /></div>
      <div id='service' className="service"><Services /></div>
      <div className="horiz"><Fanchr/></div>
      <div id='pricing' className="pricing"><Pricing /></div>
      <div id='hitw' className="hitw"><HowItWorks /></div>
      <div className="horiz"><Fanchr/></div>
      <div id='footer' className="footer"><Footer /></div>
    </div>
    
    
  );
}

export default HomePage;
