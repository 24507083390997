import React from "react";
import "./payment.css";
import axios from "axios";

class Payment extends React.Component{
  state = {
    paymentmethod:"",
    subscriptionlen:"",
    subscriptiontype:"",
    amount:0,
  }

  handleInput=(e)=>{
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  submitPay=(e)=>{
    this.state.subscriptionlen=document.querySelector('#subscription-length').value
    this.state.subscriptiontype=document.querySelector('#subscription-type').value
    this.state.paymentmethod=document.querySelector('#payment-method').value
    e.preventDefault();
      console.log(this.state.paymentmethod)
      axios.post("https://codepro.insa.gov.et/payregister/",{
        paymentmethod: this.state.paymentmethod,
        subscriptionlen: this.state.subscriptionlen,
        subscriptiontype :this.state.subscriptiontype,
        amount: this.state.amount,
      })
      .then((res)=>{
          this.setState({
            paymentmethod:"",
            subscriptionlen:"",
            subscriptiontype:"",
            amount:0,
          });
      })
      .catch((err) =>{});

  };

render(){
  return (
    <>
      <div className="body_paymentSection">
        <h2 class="form-title">Upgrade Subscription</h2>
        <div class="form-group">
          <label for="subscription-type" class="form-label">
            Subscription Type
          </label>
          <select id="subscription-type" class="form-select" name='subscriptiontype' value={this.state.subscriptiontype} onSelect={this.handleInput}>
            <option value="free">Free</option>
            <option value="basic">Basic</option>
            <option value="premium">Premium</option>
          </select>
        </div>
        <div class="form-group">
          <label for="subscription-length" class="form-label">
            Subscription Length
          </label>
          <select id="subscription-length" class="form-select" name='subscriptionlen' value={this.state.subscriptionlen} onChange={this.handleInput}>
            <option value="free">Free</option>
            <option value="6-months">6 Months</option>
            <option value="1-year">1 Year</option>
          </select>
        </div>
        <div class="form-group">
          <label for="amount" class="form-label">
            Amount
          </label>
          <input type="text" id="amount" name="amount" value={this.state.amount} onChange={this.handleInput}/>
        </div>
        <div class="form-group">
          <label for="payment-method" class="form-label">
            Payment Method
          </label>
          <select id="payment-method" class="form-select" value={this.state.paymentmethod} onSelect={this.handleInput}>
            <option value="chapa">Chapa</option>
            <option value="paypal">PayPal</option>
            <option value="card">Credit/Debit Card</option>
          </select>
        </div>
        <button class="submit-button" onClick={this.submitPay}>Pay</button>
      </div>
    </>
  );
};
}
export default Payment;
