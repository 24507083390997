import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './setpass.css';  // Import the CSS file
import logo from '../../assets/CodePRo.png';  // Import the logo image
import { validEmail, validPassword } from '../../pages/regex/regex';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';

const Setpass =(email)=>{
  
  const [password,setpassword]=useState("");
  const [password1,setpassword1]=useState("");
  const[passErr,setpassErr]=useState(false)
  const nvigate = useNavigate();
  const locate = useLocation();

  const validate=(from)=>{
    console.log('reach here')
    
    if(validPassword.test(password)){
            console.log('invalid pass') 
            if (password.length>=8){
                console.log('pass length')
              setpassErr(true)   
            }  
            
          }
      
  };

  const submitLogin=(e)=>{
    e.preventDefault();
    console.log(locate.state.email)

    validate('pass')
    console.log(passErr)

    if(passErr){
      if(password===password1){
        axios.post("https://codepro.insa.gov.et/setpass/",{
            email: locate.state.email,
            password: password,
            password1: password1,
          })
          .then((res)=>{
            console.log(res.data)
            nvigate('/signin')
                    
          })
        .catch((err) =>{});
      }
      else{
        alert("there is password mismatch")
      }
    }
    else{
      if(!passErr){
        document.getElementById('errorsta').innerText="Put eight length password "
      }
    }
  
  };

  return (
    <section className='herosp-section'>
      <div className='body-login'>
        <div className="container-login">
          
          <div className="left-section">
            <div className="logo-login">
                <a href="/">
                    <img src={logo} alt="Logo" class="logo"/>
                </a>
            </div>
            <p id='errorsta'></p>
            <form onSubmit={submitLogin} >
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" value={password} onChange={e=>setpassword(e.target.value)}/>

              <label htmlFor="password">Confirm Password</label>
              <input type="password" id="password1" name="password1" value={password1} onChange={e=>setpassword1(e.target.value)}/>

              <button id="button-loginn" type="submit">submit</button>
            </form>
            
          </div>

          
        </div>
      </div>
      </section>
  );
  //};
}


export default Setpass;
