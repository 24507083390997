import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import "./admin_home.css";
import ReactDOM from 'react-dom/client';
import admin_logo from "../../assets/CodePRo.png";
import Dashboard from "./Dashboard";
import Admin_user from "./admin_user";
import Paidusr from "./paidusr";
import Login from "../login/loginPage";
import axios from "axios";

const AdminHomePage = () => {
  const [selectedPage, setSelectedPage] = useState("Dashboard");
  const navigate = useNavigate();
  useEffect(()=>{
    if(sessionStorage.length===0){
      navigate("/signin")
      alert("first you should login")
    } 
    
  },[]);

  const renderPage = () => {
    switch (selectedPage) {
      case "Dashboard":
        return <Dashboard />;
      case "Admin_user":
        return <Admin_user />;
      case "Paid-usr":
        return <Paidusr />;
      //case "Settings":
        //return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  const backHome=()=>{
    axios.request("https://codepro.insa.gov.et/logut/").
    then((res)=>{
      console.log(res.data)
      console.log(sessionStorage.getItem)
      navigate('/signin')
      /*const link = document.createElement('a')
      link.href = "/login"
      document.body.appendChild(link)
      link.click()
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(<Login/>);*/
    })
  }

  return (
    <div className="admin_main_container">
      <div className="sidebar">
        <img src={admin_logo} alt="Admin Logo" />
        <ul>
          <li
            className={selectedPage === "Dashboard" ? "active" : ""}
            onClick={() => setSelectedPage("Dashboard")}
          >
            <i className="icon-dashboard"></i> Dashboard
          </li>
          <li
            className={selectedPage === "Admin_user" ? "active" : ""}
            onClick={() => setSelectedPage("Admin_user")}
          >
            <i className="icon-Admin_user"></i> Users
          </li>
          <li
            className={selectedPage === "Paid-usr" ? "active" : ""}
            onClick={() => setSelectedPage("Paid-usr")}
          >
            <i className="icon-Admin_user"></i> Paid Customer
          </li>
          
          
        </ul>
      </div>

      <div className="admin_content">
      <div class="admin_navbar">
            <div class="admin_welcome">
                Hello,<br/>
                <span>Welcome back</span>
            </div>
            <button className='lgoutbtn' onClick={backHome}>Logout</button>
        </div>
        {renderPage()}
      </div>
    </div>
  );
};

export default AdminHomePage;
