import React, { useState, useEffect} from "react";
import ReactDOM from 'react-dom/client';
import Signup from "../../pages/signup/signup";
import Login from "../../pages/login//loginPage";
import Set from "../../pages/Settings/Settings";
import pylog from "../../assets/scologo/pythonnbg.png";
import javalog from "../../assets/scologo/javanbg.png";
import cpplog from "../../assets/scologo/c++nbg.png";
import csharplog from "../../assets/scologo/csharpnbg.png";
import clog from "../../assets/scologo/Cnbg.jpg";
import { useNavigate,useHref} from "react-router-dom";
import JSZip, { file, files } from "jszip";
import { saveAs } from "file-saver";
import "./obfuscate.css";
import axios from "axios";
import jQuery from 'jquery';
import { Checkbox } from "@mui/material";

axios.defaults.withCredentials =true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken' 
const Obfuscate = (e) => {
  const [uplofiles, setFiles] = useState([{}]);
  const [projectname, setProjectname] = useState("");
  const [data, setdata] = useState()
  const [cmpil, setCmpil] = useState("Source")
  const [archt, setArcht] = useState("null")
  const [frmt, setFrmt] = useState("null")
  const navigate=useNavigate()
  const slides = [
    {
      img:  csharplog
    },
    {
      img: javalog
    },
    {
      img:cpplog
    },
    {
      img: pylog
    },
    {
      img:clog 
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(()=>{
    axios.request('https://codepro.insa.gov.et/getusr/').
    then((res)=>{
      console.log(res.data)  
      if (res.data.length===0){
        setdata("null")
        console.log(data)
      }
      else{ 
        console.log(res.data[0]['email'])
        setdata(res.data[0]['email']) 
      }
    }).catch((err)=>{console.log(err)});
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, 3000);

      return () => clearInterval(timer);
  },[]);
  
  const get_cookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  };

  const UploadFiles = function (event) {
    console.log(event.target.files)
    setFiles(event.target.files)
    console.log("after setfile")
    console.log(Object.values(uplofiles))
    console.log(uplofiles)
    console.log(Object.values(uplofiles)[0])//.keys(files)[0])
    /*setFiles(event.target.files);
    for(let i=0;i<event.target.files.length;i++){
      console.log(event.target.files[i])
      setFiles(event.target.files[i]);
      console.log(files)
    }*/
    //console.log(files)
    
    console.log("reach here in upload")
    //InsertFiles(event.target.files);
  };

  const submitFile=()=>{
    
    console.log(get_cookie('csrftoken'))
    
    if(data==="null"){
      console.log("login success")
          if(window.confirm("do you have an account","Signin","Signup")){
            console.log("register")
            const root = ReactDOM.createRoot(document.getElementById('root'));
            root.render(<Login/>);
          }
          else{
            const root = ReactDOM.createRoot(document.getElementById('root'));
            root.render(<Signup/>);
          }
    }
    else{
      console.log(uplofiles.length)
      console.log(uplofiles.length)

      const zip = new JSZip();
      //Object.values(uplofiles)[0].forEach(file=>zip.file(file.name,file));
      //console.log(zip)
      let allzip
      let formData = new FormData();
      for(let i=0;i<uplofiles.length;i++){
        let file=uplofiles[i]
        //allzip=zip.file(file.name,file)
        //console.log(uplofiles[i])
        formData.append("py",uplofiles[i])
      }
      const zipcontent = zip.generateAsync({type:'blob'});
      console.log(zipcontent)
      //formData.append("py",zipcontent,'zipped.zip')
      //var crf_token = $('[name="csrfmiddlewaretoken"]').attr('value');
      //console.log(get_cookie('csrftoken'))
      let axioConfig={
        headers:{
          'Content-Type':'multipart/form-data',
          'X_CSRFToken':get_cookie('csrftoken'),
        },        
        Credential:'include',
        withCredentials:true,
      }
      console.log("uploded file in formdata")
      //console.log(formData)
      //console.log(formData.getAll('py'))

  
      //console.log(cmpil)
      //console.log(archt)
      //console.log(frmt)
      //console.log(projectname)
      if(projectname===''){
       
          alert('Enter project name')
          return
      }
    axios.post("https://codepro.insa.gov.et/upload/"+cmpil+"/"+archt+"/"+frmt,{
        csrfmiddlewaretoken: get_cookie('csrftoken'),
        projectname: projectname,
        file: formData.getAll('py'),
        
      },axioConfig).
      then((res)=>{
        //console.log(res.data)
        //console.log(data)
        const strin='archieved'
        switch (res.data){
          case 'not paid':
            alert("have not enough subscription for this service")
            break;
          case '3':
            //console.log("project name found")
            alert("You enter Project name entered before")
            break;
          case 'paid':
            //console.log("Archive")
            //const root = ReactDOM.createRoot(document.getElementById('root'));
            //root.render(<Settings id="archieved"/>);
            //console.log(strin)
            navigate(0,{state:{id:'archieved'}})
            //renderSett()
            //const root = ReactDOM.createRoot(document.getElementById('root'));
            //root.render(<Set id="archieved"/>);
            break;
          default:
            //console.log("login fail")                   
        }
        /*if (res.data==='not paid'){
          alert("have not enough subscription for this service")
        }
        else if (res.data==='3'){
          console.log("project name found")
          alert("You enter Project name entered before")
        }
        else if (res.data==='paid'){
          console.log("Archive")
          const root = ReactDOM.createRoot(document.getElementById('root'));
          root.render(<Settings id={"archieved"}/>);
        }
        else{
          console.log("login fail")
        }*/
        
    })
      .catch((err) =>{console.log(err.data)});
    
    
    }

  };
  const handlselect=()=>{
    console.log(document.querySelector('#formt').value)
    if(document.getElementById('check').checked){
      setFrmt(document.querySelector('#formt').value)
    }
    else{
      setFrmt("")
    }
    
  };
  const handlselectarch=()=>{
    console.log(document.querySelector('#arch').value)
    if(document.getElementById('check').checked){
      setArcht(document.querySelector('#arch').value)
    }
    else{
      setArcht("")
    }
    
  };

  function DropDown(){
    console.log("from render")
    return(
      <div className="selectDll">
        <select id='formt' onChange={handlselect} required>
          <option value="exe" >Exe</option>
          <option value="dll">Dll</option>
        </select>
        <select id='arch' onChange={handlselectarch} required>
          <option value="32bit">32bit</option>
          <option value="64bit" >64bit</option>
        </select>
      </div>
    );
  }
  const checkState=(arch)=>{
    console.log(arch)
    console.log(document.getElementById('check').checked)
    if (document.getElementById('check').checked){
      setCmpil(arch)
      setArcht("32bit")
      setFrmt("exe")
      console.log(cmpil)
      const checkex=ReactDOM.createRoot(document.getElementById('exe'));
      checkex.render(<DropDown />)
    }  
    else{
      setCmpil('Source')
      setArcht("null")
      setFrmt("null")
      console.log(cmpil)
      document.getElementById('exe').innerText=''
    }
  }

  const checkStat=(arch)=>{
    console.log(arch)
   if (document.getElementById('check1').checked){
      setArcht(arch)
    }
    else{
      setArcht("64bit")
    }
    
  };
  
  return (
    <>
    <table>
      <tr>
        <th>
          <div className="form-groupup">
              <label for="projectname" >
                Project Name
              </label>
              <input type="text" id="projectname" name="projectname" required value={projectname} onChange={e => setProjectname(e.target.value)}/>
          </div>
          <div className="drag-and-drop-wrapper">
            <input type="file" id="file" name="file" webkitdirectory="True" multiple="True" mozdirectory="True" directory="True" required onChange={UploadFiles} />
            <ul className="file-list">
              {Object.values(uplofiles).map((file)=>{
                console.log(Object.values(uplofiles)[0].length)
                return(
                  <>
                  <li className="file-item">
                    <span>{file['name']}</span>        
                  </li>
                  </>
                )
              })}
            </ul>
          </div>
          <div className="select_frmt">
            <label>Select format</label>  
            
            <div className="checkbox">
              <input id='check' type='checkbox' name='compile' value="compile" onChange={e=>checkState(e.target.value)}/>
              <label>Compile</label>
              
            </div>
            <div id='exe'></div>
            </div>
          <button className="obfuscatebtn" onClick={submitFile}>Obfuscate</button>
      </th>
      <th>
     <div className="imgslide">
          <img src={slides[currentIndex].img}/>
     </div>
     </th>
     </tr>
     </table>
    </>
  );
};

export default Obfuscate;
